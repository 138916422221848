import React, { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { MdDone, MdFavorite, MdWaterDrop } from 'react-icons/md';

import SwipeableViews from 'react-swipeable-views';
import { SlideRendererCallback, virtualize } from 'react-swipeable-views-utils';

import { toISO, nextMonth } from 'dates';
import { calenderURL } from 'urls';
import db, { Entry } from 'db';
import { useLiveQuery } from 'hooks/useLiveQuery';

import type { DayState, WeekState } from './state';
import { getMonthKey, useDaysOfWeek, useMonthState } from './state';

import './Calendar.scss';
import useRipple from 'hooks/useRipple';

type EntryMap = { [date: string]: Entry };

interface DayProps {
  entry?: Entry;
  day: DayState;
  onChange: (date: Date) => void;
}

function Day({ entry, day, onChange }: DayProps) {
  const rippleRef = useRipple();

  const getClassName = () => {
    if (!day.isCurrentMonth) {
      return 'calendar-day calendar-day--secondary';
    }

    if (day.isToday) {
      return 'calendar-day calendar-day--today';
    }

    if (day.isSelected) {
      return 'calendar-day calendar-day--selected';
    }

    return 'calendar-day';
  };

  return (
    <div
      className={getClassName()}
      onClick={() => onChange(day.date)}
    >
      <div className="calendar-day__background" />
      <div className="calendar-day__ripple" ref={rippleRef} />
      <div className="calendar-day__date">
        {day.formatted}
      </div>
      {entry?.complete && (
        <div className="calendar-day__complete">
          <MdDone />
        </div>
      )}
      <div className="calender-day__summary">
        {!!entry?.bleeding && (
          <MdWaterDrop
            className={`calender-day__bleeding calender-day__bleeding--${entry.bleeding}`}
          />
        )}
        {!!entry?.intercourse && (
          <MdFavorite
            className="calender-day__intercourse"
          />
        )}
      </div>
    </div>
  );
}

interface WeekProps {
  entries: EntryMap;
  week: WeekState;
  onChange: (date: Date) => void;
}

function Week({ entries, week, onChange }: WeekProps) {
  return (
    <div className="calendar-week">
      {week.days.map((day) => {
        const entry = entries[day.key];
        return (
          <Day
            key={day.key}
            day={day}
            entry={entry}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}

interface MonthProps {
  date: Date;
  current: boolean;
  onChange: (date: Date) => void;
}

function Month({ date, current, onChange }: MonthProps) {
  const month = useMonthState(date);
  const daysOfWeek = useDaysOfWeek();

  const entries = useLiveQuery(
    () => (
      db.entries
        .where('date')
        .between(toISO(month.first), toISO(month.last), true, true)
        .toArray()
    ),
    [month.key],
  );

  const lookup = useMemo(
    () => {
      const result: EntryMap = {};

      if (!entries?.length) {
        return result;
      }

      for (let index = 0; index < entries.length; index += 1) {
        const entry = entries[index];
        result[entry.date] = entry;
      }

      return result;
    },
    [entries],
  );

  const getClassName = () => {
    if (current) {
      return 'calender-month calender-month--current';
    }
    return 'calender-month';
  };

  return (
    <div className={getClassName()}>
      <div className="calender-month__header">
        {
          daysOfWeek.map((day) => (
            <div key={day}>
              {day}
            </div>
          ))
        }
      </div>
      <div className="calender-month__content">
        {month.weeks.map((week) => (
          <Week
            key={week.key}
            entries={lookup}
            week={week}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}


const VirtualizeSwipeableViews = virtualize(SwipeableViews);

interface CalendarProps {
  date: Date;
}

export default function Calendar({ date }: CalendarProps) {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);

  const handleDateChange = useCallback(
    (date: Date): void => {
      navigate(calenderURL(date));
    },
    [navigate],
  );

  const handleSwipe = useCallback(
    (index: number) => {
      const next = nextMonth(date, index - offset);
      handleDateChange(next);
      setOffset(index);
    },
    [handleDateChange, setOffset],
  );

  const renderMonth: SlideRendererCallback = ({ index }) => {
    const current = index === offset;
    const slideDate = current ? date : nextMonth(date, index - offset);
    const slideKey = getMonthKey(slideDate);
    return (
      <Month
        key={slideKey}
        date={slideDate}
        current={current}
        onChange={handleDateChange}
      />
    );
  };

  return (
    <VirtualizeSwipeableViews
      index={offset}
      overscanSlideAfter={1}
      overscanSlideBefore={1}
      onChangeIndex={handleSwipe}
      slideRenderer={renderMonth}
    />
  );
}
