function zeroPad(value: number, digits: number) {
  return value.toString().padStart(digits, '0');
}

export function fromISO(value: string): Date {
  const [year, month, date] = value.split('-', 3);
  return new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(date, 10),
  );
}

export function toISO(date: Date): string {
  const yyyy = zeroPad(date.getFullYear(), 4);
  const mm = zeroPad(date.getMonth() + 1, 2);
  const dd = zeroPad(date.getDate(), 2);
  return `${yyyy}-${mm}-${dd}`;
}

export function today(): Date {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}


export function isEqual(a: Date, b: Date): boolean {
  return (
    a.getFullYear() == b.getFullYear()
    && a.getMonth() == b.getMonth()
    && a.getDate() == b.getDate()
  );
}

export function isToday(date: Date): boolean {
  return isEqual(date, new Date());
}

export function addDays(date: Date, days: number): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
}

export function nextMonth(date: Date, offset = 1): Date {
  return new Date(date.getFullYear(), date.getMonth() + offset);
}
