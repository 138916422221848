import db from 'db';
import { exportDB, importInto } from 'dexie-export-import';

export function backup() {
  exportDB(db).then((blob) => {
    const filename = `deb-${Date.now()}.json`;
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
}

export function load() {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'application/json';
  input.style.display = 'none';
  document.body.appendChild(input);

  const onClose = () => {
    window.removeEventListener('focus', onClose);
    document.body.removeChild(input);
  };

  window.addEventListener('focus', onClose);

  input.addEventListener('change', () => {
    if (!input.files) {
      return;
    }

    const file = input.files[0];
    const options = {
      acceptMissingTables: false,
      acceptVersionDiff: true,
      acceptNameDiff: false,
      clearTablesBeforeImport: true,
    };

    importInto(db, file, options).then(
      () => alert('Import successful!'),
      (error) => alert(error || 'Error importing data.'),
    );
  });

  input.click();
}
