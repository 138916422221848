import React, { ChangeEvent, useEffect, useState } from 'react';

import db, { Entry } from 'db';
import { toISO } from 'dates';

import '@rmwc/checkbox/styles';
import { Checkbox } from '@rmwc/checkbox';

import '@rmwc/grid/styles';
import { Grid, GridCell } from '@rmwc/grid';

import '@rmwc/slider/styles';
import { Slider, SliderOnInputEventT } from '@rmwc/slider';

import '@rmwc/textfield/styles';
import { TextField } from '@rmwc/textfield';

import '@rmwc/typography/styles';
import { Typography } from '@rmwc/typography';

import { useLiveQuery } from 'hooks/useLiveQuery';

import './DayForm.scss';

function keysOf<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

interface DayFormProps {
  date: Date;
}

export default function DayForm({ date }: DayFormProps) {
  const iso = toISO(date);

  const entry = useLiveQuery(
    () => (
      db.entries
        .where('date')
        .equals(iso)
        .first()
    ),
    [iso],
  );

  const getState = () => ({
    date: iso,
    bleeding: 0,
    complete: false,
    intercourse: false,
    notes: '',
    ...entry,
  });

  const [state, setState] = useState(getState);
  useEffect(() => setState(getState()), [iso, entry]);

  const shouldUpdate = (changes: Partial<Entry>) => {
    if (entry == null) {
      return true;
    }
    return keysOf(changes).some((key) => changes[key] !== state[key]);
  };

  const update = (changes: Partial<Entry>) => {
    if (!shouldUpdate(changes)) {
      return;
    }

    const now = (new Date()).toISOString();

    const result = {
      created: now,
      ...state,
      ...changes,
      updated: now,
    };

    if (entry == null) {
      result.complete = true;
    }

    setState(result);
    db.entries.put(result);
  };

  return (
    <Grid>
      <GridCell span={12}>
        <Typography use="body1" tag="label" htmlFor="day-form__bleeding-input">
          Bleeding
        </Typography>
        <Slider
          id="day-form__bleeding-input"
          className="day-form__bleeding"
          value={state.bleeding}
          min={0}
          max={5}
          step={1}
          color="secondary"
          onInput={(event: SliderOnInputEventT) => {
            update({ bleeding: event.detail.value });
          }}
          displayMarkers
          discrete
        />
      </GridCell>

      <GridCell span={12}>
        <Checkbox
          id="day-form__intercourse-input"
          className="day-form__intercourse"
          label="Intercourse"
          checked={state.intercourse}
          onChange={(event: ChangeEvent<HTMLInputElement>) => update({
            intercourse: event.currentTarget.checked,
          })}
        />
      </GridCell>

      <GridCell span={12}>
        <TextField
          id="day-form__notes-input"
          className="day-form__notes"
          label="Notes"
          value={state.notes}
          onChange={(event: ChangeEvent<HTMLInputElement>) => update({
            notes: event.currentTarget.value,
          })}
          rows={3}
          outlined
          textarea
        />
      </GridCell>

      <GridCell span={12}>
        <Checkbox
          id="day-form__complete-input"
          className="day-form__complete"
          label="Mark day complete"
          checked={state.complete}
          onChange={(event: ChangeEvent<HTMLInputElement>) => update({
            complete: event.currentTarget.checked,
          })}
        />
      </GridCell>
    </Grid>
  );
}
