import {
  DependencyList,
  useDebugValue,
  useEffect,
  useState,
} from 'react';

import { liveQuery } from 'dexie';

interface UseLiveQuery<T> {
  result: T;
  error: Error | null;
}

export function useLiveQuery<T>(
  querier: () => Promise<T> | T,
  deps?: DependencyList,
): T | undefined;

export function useLiveQuery<T, TDefault>(
  querier: () => Promise<T> | T,
  deps: DependencyList,
  defaultResult: TDefault
): T | TDefault;

export function useLiveQuery<T, TDefault>(
  querier: () => Promise<T> | T,
  deps: DependencyList = [],
  defaultResult?: TDefault
): T | TDefault | undefined {
  const [state, setState] = useState<UseLiveQuery<T | TDefault | undefined>>({
    result: defaultResult,
    error: null,
  });

  // Integrate with react devtools:
  useDebugValue(state);

  // Subscribe to the observable
  useEffect(() => {
    const observable = liveQuery(querier);
    const subscription = observable.subscribe(
      (result) => {
        setState({ result, error: null });
      },
      (error) => {
        setState({ result: undefined, error });
      }
    );
    return () => subscription.unsubscribe();
  }, deps);


  // Throw if observable has emitted error so that
  // an ErrorBoundrary can catch it
  if (state.error !== null) throw state.error;

  // Return the current result
  return state.result;
}
