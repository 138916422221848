import Dexie, { Table } from 'dexie';

import { fromISO, toISO } from 'dates';

export interface Entry {
  id?: number;
  date: string;
  complete: boolean;
  bleeding: number;
  intercourse: boolean;
  notes: string;
  created: string;
  updated: string;
}

export class DebDexie extends Dexie {
  entries!: Table<Entry>;

  constructor() {
    super('deb');

    this.version(1).stores({
      entries: '++id, &date, complete, bleeding, intercourse, masturbation',
    });

    this.version(2).stores({
      entries: '++id, &date, complete, bleeding, intercourse',
    }).upgrade((trans) => {
      return trans.table('entries').toCollection().modify((entry) => {
        delete entry.masturbation;
      });
    });

    this.version(3).upgrade((trans) => {
      return trans.table('entries').toCollection().modify((entry) => {
        entry.date = toISO(fromISO(entry.date));
      });
    });
  }
}

const db = new DebDexie();
export default db;
