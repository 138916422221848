import { useMemo } from 'react';

import { LOCALE } from 'format';
import { addDays, isEqual, toISO } from 'dates';

const WEEKS = [0, 1, 2, 3, 4, 5];
const DAYS = [1, 2, 3, 4, 5, 6, 7];

const WeekdayFormatter = Intl.DateTimeFormat(LOCALE, { weekday: 'short' });
const DayFormatter = Intl.DateTimeFormat(LOCALE, { day: 'numeric' });

export type DayState = {
  key: string;
  date: Date;
  formatted: string;
  isToday: boolean;
  isSelected: boolean;
  isCurrentMonth: boolean;
};

export type WeekState = {
  key: string
  days: DayState[];
};

export type MonthState = {
  key: string;
  weeks: WeekState[];
  first: Date;
  last: Date;
};

export function getMonthKey(date: Date): string {
  return `${date.getFullYear()}:${date.getMonth()}`;
}

export function useDaysOfWeek(): string[] {
  return useMemo(
    () => {
      const start = new Date();
      const dayOffset = start.getDay() + 1;
      return DAYS.map((day) => WeekdayFormatter.format(addDays(start, day - dayOffset)));
    },
    [],
  );
}


export function useMonthState(current: Date): MonthState {
  const year = current.getFullYear();
  const month = current.getMonth();
  const date = current.getDate();

  return useMemo(
    () => {
      const key = getMonthKey(current);

      const first = new Date(year, month);
      const dayOffset = first.getDay() + 1;

      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      const weeks = WEEKS.map((week) => ({
        key: `${key}:${week}`,
        days: DAYS.map((day) => {
          const date = addDays(first, (7 * week) + day - dayOffset);
          return {
            date,
            key: toISO(date),
            formatted: DayFormatter.format(date),
            isToday: isEqual(date, today),
            isSelected: isEqual(date, current),
            isCurrentMonth: date.getMonth() === month,
          };
        }),
      }));

      return {
        key,
        weeks,
        first: weeks[0].days[0].date,
        last: weeks[WEEKS.length - 1].days[DAYS.length - 1].date,
      };
    },
    [year, month, date],
  );
}
