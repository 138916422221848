import { RefCallback, useEffect, useState } from 'react';

import { MDCRipple } from '@material/ripple';

export default function useRipple(): RefCallback<Element> {
  const [root, setRoot] = useState<Element | null>(null);

  useEffect(
    () => {
      if (root === null) {
        return;
      }
      const ripple = new MDCRipple(root);
      return () => ripple.destroy();
    },
    [root],
  );

  return setRoot;
}
