import React from 'react';

import { useNavigate } from 'react-router-dom';

import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';

import '@rmwc/icon-button/styles';
import { IconButton } from '@rmwc/icon-button';

import { formatDateTime } from 'format';
import { calenderURL } from 'urls';
import { addDays } from 'dates';

import './DayNavigation.scss';

interface DayNavigationProps {
  date: Date;
}

export default function DayNavigation({ date }: DayNavigationProps) {
  const navigate = useNavigate();

  const navigateTo = (offset: number) => {
    navigate(calenderURL(addDays(date, offset)));
  };

  return (
    <div className="day-navigation-grid">
      <div className="day-navigation-grid__inner">
        <div className="day-navigation-grid__cell">
          <IconButton
            className="day-navigation-grid__nav"
            onClick={() => navigateTo(-1)}
            icon={<MdArrowBackIosNew className="material-icons" />}
          />
        </div>
        <div className="day-navigation-grid__cell">
          <div className="day-navigation-grid__label">
            { formatDateTime(date, { 'dateStyle': 'medium' }) }
          </div>
        </div>
        <div className="day-navigation-grid__cell">
          <IconButton
            className="day-navigation-grid__nav"
            onClick={() => navigateTo(1)}
            icon={<MdArrowForwardIos className="material-icons" />}
          />
        </div>
      </div>
    </div>
  );
}
