import React from 'react';

import DayForm from './DayForm';
import DayNavigation from './DayNavigation';

interface DayDetailsProps {
  date: Date;
}

export default function DayDetails({ date }: DayDetailsProps) {
  return (
    <div>
      <DayNavigation date={date} />
      <DayForm date={date} />
    </div>
  );
}
