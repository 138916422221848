import React from 'react';

import { useParams } from 'react-router-dom';

import { fromISO } from 'dates';

import Calendar from 'components/Calendar';
import DayDetails from 'components/DayDetails';
import Header from 'components/Header';

function useDate(): Date {
  const { date } = useParams();
  if (date == null) {
    throw new Error('date not found');
  }
  return fromISO(date);
}

export default function DayPage() {
  const date = useDate();
  return (
    <>
      <Header date={date} />
      <main className="mdc-top-app-bar--fixed-adjust">
        <Calendar date={date} />
        <DayDetails date={date} />
      </main>
    </>
  );
}
