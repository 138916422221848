import React from 'react';

import { useNavigate } from 'react-router-dom';

import { MdMoreVert, MdToday } from 'react-icons/md';

import '@rmwc/top-app-bar/styles';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarActionItem,
} from '@rmwc/top-app-bar';

import '@rmwc/menu/styles';
import { SimpleMenu, MenuItem } from '@rmwc/menu';

import { today } from 'dates';
import { formatDateTime } from 'format';
import { calenderURL } from 'urls';

import { backup, load } from 'backup';

import './Header.scss';

interface HeaderProps {
  date: Date;
}

export default function Header({ date }: HeaderProps) {
  const navigate = useNavigate();

  const navigateToToday = (): void => {
    navigate(calenderURL(today()));
  };

  return (
    <TopAppBar>
      <TopAppBarRow>
        <TopAppBarSection alignStart>
          <TopAppBarTitle>
            { formatDateTime(date, { month: 'long', year: 'numeric' }) }
          </TopAppBarTitle>
        </TopAppBarSection>
        <TopAppBarSection alignEnd>
          <TopAppBarActionItem icon={<MdToday />} onClick={navigateToToday} />
          <SimpleMenu handle={<TopAppBarActionItem icon={<MdMoreVert />} />}>
            <MenuItem onClick={backup}>
              Download data
            </MenuItem>
            <MenuItem onClick={load}>
              Import data
            </MenuItem>
          </SimpleMenu>
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  );
}
