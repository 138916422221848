import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '@rmwc/theme/styles';
import { ThemeProvider } from '@rmwc/theme';

import IconSVG from 'assets/icons/icon.svg';
import AppleTouchIcon from 'assets/icons/icon.png';
import theme from 'assets/theme.json';

import DayPage from 'components/DayPage';
import TodayRedirect from 'components/TodayRedirect';

export default function App() {
  return (
    <ThemeProvider options={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DEB</title>
        <meta name="description" content="An app for blood spatter analysis." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" href={IconSVG} type="image/svg+xml" />
        <link rel="apple-touch-icon" href={AppleTouchIcon} />

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />

        <body className="mdc-typography mdc-theme--background" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/calendar/:date" element={<DayPage />} />
          <Route path="*" element={<TodayRedirect />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
